// Generated by Framer (dc84125)

import { addFonts, cx, CycleVariantState, getFonts, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import { Icon as Feather } from "https://framerusercontent.com/modules/f0DboytQenYh21kfme7W/0pLhZgteIMGONoge6j70/Feather.js";
const FeatherFonts = getFonts(Feather);

const cycleOrder = ["jMnaimtV2"];

const variantClassNames = {jMnaimtV2: "framer-v-cx8m46"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "jMnaimtV2", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "jMnaimtV2", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-PRU6q", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-cx8m46", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"jMnaimtV2"} ref={ref} style={{...style}} transition={transition}><motion.div className={"framer-1jy8zl3-container"} layoutDependency={layoutDependency} layoutId={"D3e6bvQ8Z-container"} transition={transition}><Feather color={"rgb(102, 102, 255)"} height={"100%"} iconSearch={"arrow"} iconSelection={"home"} id={"D3e6bvQ8Z"} layoutId={"D3e6bvQ8Z"} mirrored={false} selectByList={false} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-PRU6q [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-PRU6q * { box-sizing: border-box; }", ".framer-PRU6q .framer-vt0nl7 { display: block; }", ".framer-PRU6q .framer-cx8m46 { height: 60px; overflow: hidden; position: relative; width: 60px; }", ".framer-PRU6q .framer-1jy8zl3-container { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 60
 * @framerIntrinsicWidth 60
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramerntyZt6Ze1: React.ComponentType<Props> = withCSS(Component, css, "framer-PRU6q") as typeof Component;
export default FramerntyZt6Ze1;

FramerntyZt6Ze1.displayName = "CMS Arrow";

FramerntyZt6Ze1.defaultProps = {height: 60, width: 60};

addFonts(FramerntyZt6Ze1, [...FeatherFonts])